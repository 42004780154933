
@import "bootstrap/scss/bootstrap";




/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
  }
  body {
    /* Margin bottom by footer height */
    margin-bottom: 60px;
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;
    line-height: 60px; /* Vertically center the text there */
    background-color: #f5f5f5;
  }
  
  
  
  .problems-table-wrapper {
    overflow:auto;
    height: calc(100vh);
  }
  
  .student-col {
    position:sticky;
      left:0;
      width: 230px;
    background-color:white;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
    border-left: 2px #dee2e6 solid !important;
  }
  
  table.problems {
    text-align: center;
    table-layout: fixed;
    width: 100px;
  }
  table.rotate {
    border: 0;
  }
  tr.rotate th {
    border: 0;    
    background-color: rgba(255, 255, 255, 0);
    position: sticky;
    top: 0;
  }
  th.rotate {
    /* Something you can count on */
    height: 240px;
    white-space: nowrap;
    width: 55px;
  }
  
  th.rotate > div {
    transform: translate(32px, -9px) /* 45 is really 360 - 45 */ rotate(315deg);
    width: 32px;
  }
  th.rotate > div > span {
    border-bottom: 1px solid #ccc;
    padding: 10px 0px;
  }
  
  th.rotate {
      border: 0;
  }
  
  
  table.problems th.status-pending {
    background-color: #ffde9f;
  }
  table.problems th.status-running {
    background-color: #dadcfd;
  }
  
  
  /* Custom page CSS
  -------------------------------------------------- */
  /* Not required for template or sticky footer method. */
  
  body > div > .container {
    padding: 60px 15px 0;
  }
  
  .footer > .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  
  code {
    font-size: 80%;
  }
  
  
  .icon-refresh-animate {
      -animation: spin .7s infinite linear;
      -webkit-animation: spin2 .7s infinite linear;
  }
  
  @-webkit-keyframes spin2 {
      from { -webkit-transform: rotate(0deg);}
      to { -webkit-transform: rotate(360deg);}
  }
  
  @keyframes spin {
      from { transform: scale(1) rotate(0deg);}
      to { transform: scale(1) rotate(360deg);}
  }
  
  .sk-cube-grid {
    width: 40px;
    height: 40px;
    margin: 100px auto;
  }
  
  .sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #333;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
            animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  }
  .sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }
  .sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s; }
  .sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  .sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }
  .sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
            animation-delay: 0s; }
  .sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  .sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  
  @-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
              transform: scale3D(1, 1, 1);
    } 35% {
      -webkit-transform: scale3D(0, 0, 1);
              transform: scale3D(0, 0, 1);
    }
  }
  
  @keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
              transform: scale3D(1, 1, 1);
    } 35% {
      -webkit-transform: scale3D(0, 0, 1);
              transform: scale3D(0, 0, 1);
    }
  }
  
.navbar {
  margin-bottom: 40px;
}
  
.socket-status {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
}


.loader {
  margin: auto;
  width: 80px;
  height: 70px;
  border: 5px solid #000;
  padding: 0 8px;
  box-sizing: border-box;
  background:
    linear-gradient(#fff 0 0) 0    0/8px 20px,
    linear-gradient(#fff 0 0) 100% 0/8px 20px,
    radial-gradient(farthest-side,#fff 90%,#0000) 0 5px/8px 8px content-box,
    #000;
  background-repeat: no-repeat; 
  animation: l3 2s infinite linear;
}
@keyframes l3{
25% {background-position: 0 0   ,100% 100%,100% calc(100% - 5px)}
50% {background-position: 0 100%,100% 100%,0    calc(100% - 5px)}
75% {background-position: 0 100%,100%    0,100% 5px}
}

.editing .form-control {
  background-color: #e8f1ff;;
}

.page-edit {
  margin-left: 10px;
}

.connected-badge {
  background-color: #28a745;
}
.disconnected-badge {
  background-color: #dc3545;
}
.connected-badge, .disconnected-badge {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
}

.clickable {
  cursor: pointer;
}